@use "navbar";
@import url("https://meyerweb.com/eric/tools/css/reset/reset.css");

.app {
    background-color: black;
    height: 100vh;
    display: flex;
    color: white;
}

.app-container {
    width: 100%;
    height: max-content;
    padding: 128px;
    background-color: black;
}

.container {
    color: white;
}

@media screen and (max-width: 850px) {
    .app-container {
        padding: 16px;
    }
}

a {
    text-decoration: underline;
    color: rgb(0, 81, 255);
}