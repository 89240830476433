.navbar {
    width: 100%;
}

.navbar>ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.navbar li {
    position: relative;
    z-index: 0;
    color: white;
    padding: 4px 8px;
}

.navbar li::before {
    content: '';
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s ease;
    height: 0;
    width: 100%;
}

.navbar li:hover::before {
    height: 100%;
}

.navbar span {
    mix-blend-mode: difference;
}

@media screen and (max-width: 850px) {
    .navbar>ul {
        width: 100%;
        justify-content: center;
        padding: 0;
    }
}